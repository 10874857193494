(function($){
    $(document).ready(function(){
        if($('.reviews-slider').length > 0){
            $('.reviews-slider').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 500,
                cssEase: 'linear',
                touchThreshold: 90,
            })
        }
    });
})(jQuery);
