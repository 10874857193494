(function($){
    $('body').on('click', 'header nav a, .navigation-container-mobile nav a', function(e){
        if($(this).attr('href').indexOf('#') > -1){
            if($('body').hasClass('home')){
                e.preventDefault();
                var hash = $(this).attr('href').replace('en/', '').replace('/', ''), hh = $('header').height();
                $('html, body').animate({ scrollTop: $(hash).offset().top - hh - 20}, 1000);
            }
        }
    })
    if(window.location.hash != ''){
        var hash = window.location.hash, hh = $('header').height();
        window.location.hash = '';
        if($(hash).length > 0){
            $('html, body').animate({ scrollTop: $(hash).offset().top - hh - 20}, 1000);
        }
    }
    $('#mobile-menu a').on('click', function(){
        $('#mobile-menu').slideUp(500);
    })
})(jQuery);
